<template>
    <v-dialog v-model="display" content-class="confirm" persistent :width="width">
        <v-card class="app-dialog no-selection dark--text">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-card-text class="pa-3 mb-0 text-center" v-html="dialogMessage"></v-card-text>

            <slot name="default" v-if="$slots.default" />

            <v-card-actions class="actions pa-3">
                <v-btn v-if="!this.showError && !this.hideCancel" :disabled="cancelDisabled || !isValid" :color="cancelColor" uppercase elevation="0" @click="cancel">
                    <span class="dark--text">{{ cancelLabel }}</span>
                </v-btn>

                <v-btn v-if="!this.showError && !this.hideAction" :disabled="actionDisabled || !isValid" :color="actionColor" uppercase elevation="0" @click="confirm">{{ actionLabel }}</v-btn>
                <v-btn v-if="this.showError" color="primary" bold uppercase elevation="0" v-text="'Close'" @click="cancel"></v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="overlay" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default() {
                return "Alert";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        actionDisabled: {
            type: Boolean,
            default: false,
        },
        cancelDisabled: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [String, Number],
            default() {
                return 500;
            },
        },
        hideAction: {
            type: Boolean,
            default: false,
        },
        hideCancel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            model: null,
            message: "",
            errorMessage: "",
        };
    },
    computed: {
        isValid() {
            return !this.loading;
        },
        dialogMessage() {
            if (this.showError) return this.errorMessage || "An error occured. Try again.";
            return this.message || "";
        },
    },
    methods: {
        init(model) {
            this.showError = false;
            this.loading = false;

            if (!model) {
                this.model = null;
            } else {
                let clonedModel = JSON.parse(JSON.stringify(model));
                this.model = clonedModel;
            }
        },
        open(model, message) {
            this.init(model);
            this.message = message;
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            this.$emit("confirm", this.model);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    position: relative;
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.app-dialog {
    .dialog-error {
        height: 0;
        overflow: hidden;
        transition: height 0.2s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &.active {
            height: 60px;
        }
    }

    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }

    .overlay {
        position: absolute;
    }
}
</style>
