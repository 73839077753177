<template>
    <div>
        <app-banner :image="compImage" position="0 -200px">
            <v-container class="align-self-end py-7 px-4">
                <v-row>
                    <v-col cols="12">
                        <v-btn class="mb-2" text color="white" small :to="{ name: 'competition' }"
                            ><v-icon small class="mr-2">mdi-arrow-left</v-icon>Back</v-btn
                        >
                        <h1 class="white--text">{{ competition.title }}</h1>
                    </v-col>
                </v-row>
            </v-container>
        </app-banner>

        <v-progress-linear :indeterminate="loading" color="primary"></v-progress-linear>

        <v-container fluid class="pa-0 grey">
            <v-container class="py-7">
                <v-row>
                    <v-col class="px-5" cols="12" sm="4">
                        <v-autocomplete
                            v-model="filter.artists"
                            multiple
                            solo
                            hide-details
                            placeholder="Artist"
                            :items="artists"
                            item-text="title"
                            item-value="_id"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="px-5" cols="12" sm="4">
                        <v-autocomplete
                            v-model="filter.categories"
                            multiple
                            solo
                            hide-details
                            placeholder="Category"
                            :items="categories"
                            item-text="title"
                            item-value="_id"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="px-5" cols="12" sm="4">
                        <v-autocomplete
                            v-model="filter.awards"
                            multiple
                            solo
                            hide-details
                            placeholder="Award"
                            :items="awards"
                            item-text="title"
                            item-value="_id"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <v-container class="py-7 px-4">
            <v-row>
                <v-col cols="12">
                    <h1 class="text-center">Choose an Entry</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3" v-for="entry in filtered" :key="entry._id">
                    <app-tile :item="entry" @clicked="clicked">
                        <template #absolute>
                            <div class="awards">
                                <div class="mr-2" v-for="(award, index) in entryAwards(entry)" :key="index">
                                    <img v-if="award" :src="$fluro.asset.imageUrl(award)" width="40" height="40" />
                                </div>
                            </div>
                        </template>
                    </app-tile>
                </v-col>
            </v-row>
        </v-container>

        <app-dialog
            ref="dialog"
            title="Mailing List"
            action-label="Join"
            :action-disabled="!valid"
            cancel-label="No thanks"
            @confirm="confirm"
            @cancel="cancel"
        >
            <div class="pa-3 grey">
                <app-mailing-list-form
                    ref="form"
                    v-model="payload"
                    @valid="setValid"
                    @complete="complete"
                ></app-mailing-list-form>
            </div>
        </app-dialog>
    </div>
</template>

<script>
import Competition from "@/modules/fluro/competition";
import Entry from "@/modules/fluro/entry";

import AppBanner from "@/components/app-banner.vue";
import AppTile from "@/components/app-tile.vue";
import AppDialog from "@/components/app-dialog.vue";
import AppMailingListForm from "@/components/app-mailing-list-form.vue";

export default {
    name: "competition-id",

    meta() {
        return {
            title: this.competition?.title || "Loading...",
        };
    },

    components: {
        AppBanner,
        AppTile,
        AppDialog,
        AppMailingListForm,
    },

    data() {
        return {
            awards: [],
            loading: false,
            competition: {},
            entries: [],
            filter: {
                artists: [],
                categories: [],
                awards: [],
            },
            payload: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                mailingList: "63364274ffdffc2b630474cb",
            },
            valid: false,
        };
    },

    computed: {
        compImage() {
            return {
                path: this.competition?.data?.image?._id,
                fluro: true,
            };
        },
        compAwards() {
            console.log("AWARDS", this.competition?.data);
            return this.competition?.data?.awardsOld || this.competition?.data?.awards || [];
        },
        artists() {
            console.log("ARTIST", this.entries);
            let artists = this.entries.map(({ data, managedAuthor }) => data.managedAuthor || managedAuthor);
            let ids = artists.map(({ _id }) => _id);
            if (ids?.length) {
                let unique = [...new Set(ids)];
                return artists
                    .filter((artist) => unique.includes(artist._id))
                    .sort((a, b) => a.title.localeCompare(b.title));
            }
            return [];
        },
        categories() {
            let categories = this.competition?.data?.categories;
            console.log("RUNNING CAT");
            if (categories?.length) {
                return categories.sort((a, b) => a.title.localeCompare(b.title));
            }
            console.log("NO CAT");
            return [];
        },
        // awards() {
        //     let awards = this.compAwards;
        //     console.log("awards computed", awards);

        //     if (awards?.length) {
        //         const tagIds = awards.map(({ tag }) => tag);
        //         console.log("tags", tagIds);
        //         const data = this.$fluro.api.get("/content/tag");
        //         console.log("returned", data);
        //     }

        //     // .sort((a, b) => a.title.localeCompare(b.title));

        //     return [];
        // },
        filtered() {
            let entries = this.entries;

            let { artists, categories, awards } = this.filter;

            if (artists?.length) {
                entries = entries.filter((entry) => {
                    return artists.some(
                        (artist) => (entry?.data?.managedAuthor?._id || entry?.managedAuthor?._id) == artist
                    );
                });
            }

            if (categories?.length) {
                entries = entries.filter((entry) => {
                    return categories.some(
                        (category) => (entry?.data?.category?._id || entry?.data?.category) == category
                    );
                });
            }

            if (awards?.length) {
                entries = entries.filter((entry) => {
                    return awards.some(
                        (award) =>
                            entry?.tags?.some((tag) => (tag?._id || tag) == award) ||
                            entry?.data.awards.some((item) => awards.includes(item._id))
                    );
                });
            }
            console.log(entries.length);
            return entries;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            this.competition = await Competition.get(this.$route.params.id).then(({ data }) => data);

            let params = {
                _type: "article",
                status: {
                    $in: ["active"],
                },
                $or: [
                    {
                        "data.competition": this.$route.params.id,
                    },
                    {
                        "data.competition": `ObjectId(${this.$route.params.id})`,
                    },
                    {
                        "data.competition._id": this.$route.params.id,
                    },
                    {
                        "data.competition._id": `ObjectId(${this.$route.params.id})`,
                    },
                ],
            };

            let entries = await Entry.query(params).then(({ data }) => data);

            entries = entries.filter((item) => item.managedAuthor);
            this.entries = entries
                .filter((entry) => {
                    const entryAwards = this.entryAwards(entry).length;

                    return entryAwards;
                })
                .map((entry) => {
                    const returnEntry = {
                        ...entry,
                        displayTitle:
                            entry?.data?.managedAuthor?.title || entry?.managedAuthor?.title || "No Author Found",
                        subtitle: entry?.data?.category?.title || "Category not found",
                    };

                    return returnEntry;
                })
                .sort((a, b) => {
                    let aCode = a?.data?.entryCode;
                    let bCode = b?.data?.entryCode;
                    if (aCode && bCode) {
                        return aCode.localeCompare(bCode);
                    }
                });

            //Fetch tags for old system
            this.award = ["2"];

            this.loading = false;
        },
        entryAwards(entry) {
            let tags = entry?.tags;
            let awards = entry?.data?.awards || [];

            //Return here for new style
            if (awards.length) return awards;

            //Continue here for old style
            this.compAwards.forEach((award) => {
                let match = tags.find((tag) => (tag._id || tag) == (award.tag?._id || award.tag));

                if (match) {
                    awards.push(award.image);
                }
            });
            return awards;
        },
        clicked(item) {
            this.$router.push({ name: "entry-id", params: { id: item._id } });
        },
        cancel() {
            localStorage.setItem("seen_mailing_dialog", true);
        },
        async confirm() {
            await this.$refs.form.confirm();
        },
        complete() {
            this.$refs.dialog.close();
            this.cancel();
        },
        setValid(v) {
            this.valid = v;
        },
    },

    watch: {
        competition: {
            immediate: true,
            async handler(v) {
                let awards = this.compAwards;
                console.log(v);
                const isNewAwards = this.compAwards.some((item) => item.title);
                if (awards?.length && !isNewAwards) {
                    const tagIds = awards.map(({ tag }) => tag);
                    let populated = [];
                    for (const element of tagIds) {
                        const { data } = await this.$fluro.api.get(`/content/tag/${element}`);
                        populated.push(data);
                    }
                    this.awards = populated.sort((a, b) => a.title.localeCompare(b.title));
                } else {
                    this.awards = this.compAwards;
                }
            },
        },
    },

    async mounted() {
        await this.init();

        let seenMailingList = JSON.parse(localStorage.getItem("seen_mailing_dialog"));

        if (seenMailingList === true) {
            console.log("Already seen mailing list message");
        } else if (this.$mailingList.showDialogAfter === 0) {
            this.$refs.dialog.open(null, "Join our mailing list and stay in the loop for our next competition dates!");
        }
    },
};
</script>

<style lang="scss" scoped>
.awards {
    display: flex;
    align-items: center;
    transform: translate(-5px, -5px);

    img {
        -webkit-filter: drop-shadow(5px 5px 5px #333);
        filter: drop-shadow(5px 5px 5px #333);
    }
}
</style>

63226738959a94227c88937e
