import FluroContent from ".";

class Entry extends FluroContent {
    constructor() {
        super("entry");
    }
}

const _Entry = new Entry();
export default _Entry;
